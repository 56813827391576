.bar {
    position: fixed;
    top: 0;
    height: 70px;
    z-index: 900;
    width: 100vw;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebar-btn {
    color: rgb(0, 0, 0);
    scale: 1.4;
    padding-right: 20px;
    /* border-radius: 10px; */
    transition: 0.3s ease;
}

.sidebar-btn:hover {
    color: rgb(121, 121, 121);
}

.logo {
    width: 80px;
    height: auto;
    padding: 0 10px;
}


.sidebar {
    width: 250px;
    height: 100%;
    position: fixed;
    top: 0;
    left: -251px;
    background-color: #ffffff;
    transition: left 0.3s ease;
    user-select: none;
    color: black;
    backdrop-filter: blur(5px);
    border-right: 1px solid rgba(0, 0, 0, 0.199);
    z-index: 1000;
}

.sidebar.open {
    left: 0;
}



/* .title {
    font-size: 1rem;
    font-weight: bolder;
    text-align: center;
    padding: 15px 0;
    color: white;
    border-bottom: 1px solid white;
} */




.sidebar ul {
    list-style: none;
    padding-top: 20px;
}

.link {
    text-decoration: none !important;
}

.sidebar li {
    padding: 10px;
    color: black;
    transition: 0.3s ease;
    border-bottom:.5px solid rgba(0, 0, 0, 0.205) ;
    /* text-align: center; */
}

.sidebar li:hover {
    background-color: rgba(207, 207, 207, 0.425);
    color: #000000;
}

.sidebar .active {
    background-color: rgba(207, 207, 207, 0.425);
    color: #000000;
}

/* ---------------------------------------------------- Navbar ---------------------------------------------------------- */

.nav-bar {
    position: fixed;
    top: 0;
    height: 70px;
    z-index: 900;
    width: 100vw;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .logo-b {
    position: relative;
    width: 220px;
    height: auto;
    padding: 0 10px;
    margin-left: 20px;
    margin-top: 80px;
}
  
  .bar-items {
    display: flex;
    width: 70vw;
    justify-content: space-around;
    align-items: center;
  }
  
  .link {
    text-decoration: none;
    color: black;
  }
  
  .bar-item, .bar-item-active {
    padding: 10px 20px;
    transition: border-bottom 0.5s ease-in-out;
  }
  
  .bar-item:hover, .bar-item-active {
    border-bottom: 2px solid black;
  }
  