:root {
  --scrollbar-width: 0px;
  /* Width of the scrollbar */
  --scrollbar-track-color: #f1f1f1;
  /* Track color */
  --scrollbar-thumb-color: #888;
  /* Thumb color */
  --scrollbar-thumb-border-color: #f1f1f1;
  /* Border color around the thumb */
}

.background-image-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  /* Add overflow: hidden to contain the ::after pseudo-element */
}

.background-image-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  /* Set z-index to a value lower than the content */
  background-image: url("../../public/img/patternblur.png");
  background-repeat: repeat-y;
  /* Repeat the image vertically */
  background-size: cover;
  opacity: 0.7;
  filter: blur(12px);
}

@media (max-width: 767px) {
  .background-image-container::after {
    background-size: contain;
    background-repeat: repeat;
  }
}


/* Cards */


.brand-card:hover {
  transform: scale(1.05);
  /* Increase the scale on hover */
  transition: transform 0.5s ease-in-out;
  /* Add a smooth transition effect */
}

h1,
h2,
h3 {
  font-family: "Playfair Display", serif;
}


h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}



p {
  font-family: 'Times New Roman', Times, serif;
}


.custom-btn-primary {
  background-color: #bcdba9;
  /* Lighter color on hover */
  border-color: #9cc59d;
  /* Border color on hover, if needed */
  color: #ffffff;
  /* Text color on hover */

}

/* Change the button color on hover */
.custom-btn-primary:hover {

  background-color: #7f9472a8;
  /* Your custom primary color */
  border-color: #dbddc7;
  /* Border color, if needed */
  color: #050505;
  /* Text color */
}



/* Apply styles to all scrollbars */
::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--scrollbar-track-color);
}

/* Thumb */
::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
  border-radius: calc(var(--scrollbar-width) / 2);
  /* Round corners of the thumb */
  border: 3px solid var(--scrollbar-thumb-border-color);
}

.custom-navbar {
  background-color: rgba(31, 30, 30, 0) !important;
  /* Change the background color */
  background-image: linear-gradient(to bottom, rgba(9, 9, 14, 0.637), rgba(10, 8, 8, 0)) !important;
  padding-top: 0px;
  /* Adjust the top padding to reduce the height */
  padding-bottom: 0px;
}


.custom-navbar .nav-link {
  color: rgb(255, 255, 255) !important;
  /* Change the text color */
  font-family: 'Times New Roman', Times, serif;
  /* Change the font family */
  font-size: 16px;
  /* Change the font size */
  transition: background-color 0.3s ease-in-out;
  /* Add a smooth transition effect */
}

.custom-navbar .nav-link:hover {
  background-color: rgba(250, 250, 250, 0.664) !important;
  border-radius: 10px;
  /* Add a border radius for rounded corners */
  color: #000000 !important;
  /* Change the text color on hover (if needed) */
}

.bar-color {
  background: #0000004b;
  margin-right: 5px;
  border: 1px solid rgba(255, 255, 255, 0.568);
}

.brand-name {
  font-size: xx-large;
  font-family: 'Pacifico', cursive;
}

.custom-navbar .navbar-toggler-icon {
  background-color: rgba(255, 255, 255, 0) !important;
  ;
  /* Replace 'your-color-here' with your desired color */
  /* You may also need to customize the color for better visibility */
  color: rgba(247, 247, 247, 0.945) !important;
  /* Replace 'your-text-color-here' with your desired text color */
}


@media (max-width: 767px) {

  /* Styles for mobile screens */
  .custom-navbar {
    background-color: rgba(0, 0, 0, 0) !important;
    /* Start with a transparent background */
    background-image: linear-gradient(to bottom, rgba(9, 9, 14, 0.582), rgba(10, 8, 8, 0)) !important;
    /* Create a vertical gradient */
    /* background-color: rgba(9, 9, 14, 0) !important; */
  }

  .custom-navbar .nav-link {
    background-image: linear-gradient(to bottom, rgba(9, 9, 14, 0.384), rgba(10, 8, 8, 0.158)) !important;
    /* Create a vertical gradient */

  }


}

.carousel-item img {
  object-fit: cover;
  /* Crop the image to cover the entire container */
  width: 100%;
  /* Ensure the image covers the entire container width */
  height: 100%;
  /* Ensure the image covers the entire container height */
}

@media (min-width: 767px) {
  .brand-name img {
    width: 120px;
    height: 150px;
  }

  .brand-name {
    padding-right: 20vw;
  }

  .carousel-item {
    height: 90vh;
    /* Adjust the height as needed for smaller screens */
  }

}



/* ---------------------------      banner    -------------------- */


.banner {
  background: #000000;
  /* Fallback background color */
  background-image: var(--current-image);
  /* Initial image */
  background-size: cover;
  background-position: center;
  color: #fff132;
  text-align: center;
  padding: 100px 0;
  /* margin-top: 70px; */
  height: 50vh;
  animation: changeBanner 5s linear infinite;
  /* Adjust the duration (10s) as needed */
}



/* Style the banner content */
.banner-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Style the heading */
.banner h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

/* Style the description */
.banner p {
  font-size: 18px;
  margin-bottom: 30px;
}



/* ----------------------------   brand info card ---------------------- */

.brand-info-card {
  border-radius: 25px;
  height: 150px;
  display: flex;
  align-items: center;
  padding: 20px 0;
  border: .5px solid rgba(0, 0, 0, 0.164);
  opacity: 0;
}

@media (min-width: 767px) {
  .brand-info-card {
    width: 40vw;
  }
}

.brand-info-card:hover {
  color: white;
  background-color: rgb(76, 170, 63);
}

.icon-white {
  font-size: 3em;
  /* Increase the size of the icon */
  color: rgba(41, 40, 37, 0.726);
}

/* ------------------------------   Signature Products   -------------------------- */



.sp-container {
  margin-top: 20px;
  width: 100%;
  overflow-x: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sp-image-scroll-container {
  display: flex;
  flex-wrap: nowrap;
}

.sp-image-card {
  flex: 0 0 auto;
  margin-right: 10px;
  /* Add a border */
  /* border-radius: 10px;  */

}

.sp-image-card img {
  width: 20vw !important;
  border-radius: 10px;
  border: 3px solid #cccccc00;
  transition: border-color 0.3s;
  /* Smooth transition for border color */

}

.sp-image-card:hover img {
  border-color: #000000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  /* Add a shadow on hover */
}

.sp-image-card:last-child {
  margin-right: 0;
}

@media (max-width: 767px) {
  .sp-image-card img {
    width: 45vw !important;
    /* Adjust the maximum width for mobile view */
    height: auto;
    /* Maintain aspect ratio */
  }
}