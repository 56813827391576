.banner-container {
    position: relative;
    text-align: center;
  }
  
  .banner-container img {
    object-fit: cover; /* Crop the image to cover the entire container */
    width: 100%;
    height: 100%;

    /* @media (max-width: 768px) {
      height: 100%;
    } */
  }
  
  .banner-content {
    color: rgb(5, 5, 5); /* Text color for visibility */
    background: linear-gradient(to bottom right, rgba(255, 238, 0, 0.699) 49.5%, transparent 50%) 0 0/50% 100%, linear-gradient(to bottom left, rgba(255, 0, 0, 0.733) 49.5%, transparent 50%) 100% 0/50% 100%;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); 
    padding: 20px;
    text-align: center;
    position: relative;
    margin-top: 20px;
  }
  
  .banner-content:hover {
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4); /* Increase shadow on hover */
  }
  
  .banner h1 {
    font-size: 2rem; /* Customize the heading font size */
    font-weight: bolder;
  }
  
  .banner p {
    font-size: 1rem; /* Customize the description font size */
    font-weight: bolder;
  }


  /* ---------------------------- product -------------------- */


/* Apply styles to category buttons */
.bttn{
    background-color: #5dc066; /* Standard background color (you can change this) */
    border: none; /* No borders */
    padding: 10px 20px; /* Adjust padding as needed */
    cursor: pointer;
    border-radius: 0; /* Make buttons square */
    color: #fff; /* Text color (you can change this) */
    transition: background-color 0.3s ease; /* Smooth hover effect */
  }
  
  .selbtn{
    background-color: #ebebeb; /* Standard background color (you can change this) */
    border: none; /* No borders */
    padding: 10px 20px; /* Adjust padding as needed */
    cursor: pointer;
    border-radius: 0; /* Make buttons square */
    color: #1ca327; /* Text color (you can change this) */
    transition: background-color 0.3s ease; /* Smooth hover effect */
    
  }
  
  /* Hover effect */
  .bttn:hover {
    color: #1ca327;
    background-color: #ebebeb; /* Lighter color on hover (you can change this) */
  }


  .prd-list {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); /* Add shadow with a slight blur */
    transition: box-shadow 0.3s ease; /* Smooth transition on hover */
  }
  
  /* Hover effect */
  .prd-list:hover {
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4); /* Increase shadow on hover */
  }