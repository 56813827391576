.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    text-align: center;
    /* background-color: black; */
  }
  
  .loader-inner {
    position: relative;
    width: 50px; /* Adjust width as needed */
    height: 50px; /* Adjust height as needed */
  }
  
  .letter-g,
  .letter-o {
    position: absolute;
    top: 0;
    left: 0;
    font-weight: bolder;
    font-size: 50px; /* Adjust font size as needed */
    opacity: 0;
    color: rgb(255, 230, 0);
    animation: zoomInOut 2s infinite alternate ease-in-out;
  }

.letter-o {
      /* color: rgb(59, 59, 59); */
    animation-delay: 1s; /* Delay the animation of 'O' by 1 second */
  }

.gobuyget-loading {
  width: 100px;
  animation: zoomInOut 1.3s infinite alternate ease-in-out;
}
  
  @keyframes zoomInOut {
    0% {
      opacity: 1;
      transform: scale(1);
      /* color: black; */
    }
    50% {
      opacity: 0.3;
      transform: scale(0.9);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  