/* ------------------------------------ tracking-in-contract ------------------------------------ */



.tracking-in-contract {
	-webkit-animation: tracking-in-contract 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-contract 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}


 @-webkit-keyframes tracking-in-contract {
    0% {
      letter-spacing: 1em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      letter-spacing: normal;
      opacity: 1;
    }
  }
  @keyframes tracking-in-contract {
    0% {
      letter-spacing: 1em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      letter-spacing: normal;
      opacity: 1;
    }
  }



 /* ------------------------------------ slide-in-left ------------------------------------ */


 .slide-in-left {
	-webkit-animation: slide-in-left 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-left 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


 @-webkit-keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
              transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
              transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  

    /* ------------------------------------ slide-in-right ------------------------------------ */


  .slide-in-right {
	-webkit-animation: slide-in-right 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-right 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
  


 @-webkit-keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  

    /* ------------------------------------ slide-in-bottom ------------------------------------ */


  .slide-in-bottom {
	-webkit-animation: slide-in-bottom 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-bottom 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


@-webkit-keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }



/* ------------------------------------ swing-in-left-bck, LOGO ------------------------------------ */


.swing-in-left-bck {
	-webkit-animation: swing-in-left-bck 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) 0.3s both;
	        animation: swing-in-left-bck 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) 0.3s both;
}



 @-webkit-keyframes swing-in-left-bck {
    0% {
      -webkit-transform: rotateY(-70deg);
              transform: rotateY(-70deg);
      -webkit-transform-origin: left;
              transform-origin: left;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0);
              transform: rotateY(0);
      -webkit-transform-origin: left;
              transform-origin: left;
      opacity: 1;
    }
  }
  @keyframes swing-in-left-bck {
    0% {
      -webkit-transform: rotateY(-70deg);
              transform: rotateY(-70deg);
      -webkit-transform-origin: left;
              transform-origin: left;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0);
              transform: rotateY(0);
      -webkit-transform-origin: left;
              transform-origin: left;
      opacity: 1;
    }
  }