.App {
  text-align: center;
}


@media (min-width: 700px) {
  .d-below-700 {
    display: none;
  }
}


@media (max-width: 700px) {
  .d-above-700 {
    display: none;
  }
}

.bg-footer {
  background-color: rgb(161, 161, 161);
}

.display-none {
  display: none;
}

.img50 {
  width: 20vw;
}

.top-m {
  margin-top: 70px;
}

.radius-15 {
  border-radius: 15px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.a {font-family: "Audiowide", sans-serif;}
.b {
  font-family: "Bungee", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.s {font-family: "Sofia", sans-serif;}
.c {font-family: "Trirong", serif;}
.r {font-family: "Rajdhani", sans-serif;}

.intro-text {
  font-weight: 500;
  text-align: center;
  font-size: 2.5rem;
  padding: 10px;
}

.img-50 {
  width: 50px;
}

.img-100 {
  width: 100px;
}

.sub-p {
  font-size: .8rem;
  color: rgba(0, 0, 0, 0.877);
}


.pyramid-text {
  display: grid;
  justify-content: center;
  text-align: center;
  line-height: 1.5em;
}
@media (max-width: 600px) {
  .pyramid-text div {
      display: block;
  }
}
@media (max-width: 601px) {
  .intro-text {
    /* font-weight: 500; */
    font-size: 1.5rem;
  }

  .none {
    display: none;
    }
}


/* --------------------------------------------- Our Objective ------------------------------------------------- */

.our-objective {
  /* background: linear-gradient(to bottom, #db0004, #ffffff); */
  background-color: #721214;
  /* background-color: rgb(0, 90, 0); */
  color: white;
  backdrop-filter: blur(10px);
}

.our-objective .title {
  /* background: linear-gradient(to top, #db0004, #ffffff); */
  /* background-color: #3b0506; */
  background: linear-gradient(to bottom, rgba(182, 0, 0, 0.9), rgba(131, 0, 0, 0.9));
  /* background-color: rgb(0, 75, 0); */
  color: rgb(255, 255, 255);
  padding: 35px;
  font-family: "Bungee", sans-serif;
  text-align: center;
}

.objective-item {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.objective-num {
  width: 50px;
  height: 50px;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: linear-gradient(to right, #db000483 , #ffffff); */
  background-color: #420a0b;
  /* background-color: rgb(0, 102, 0); */
  color: rgba(255, 255, 255, 0.671);
  margin-bottom: 5px;
}

.objective-text {
  width: 200px;
}

@media (min-width: 800px) {
  .our-objective {
    /* display: flex; */
    flex-direction: column;
    align-items: center;
  }

  .objective-items-container {
    display: flex;
    padding: 20px 0;
    flex-wrap: wrap;
    justify-content: center;
  }

  .objective-item {
    flex-direction: row;
    width: auto;
    padding: 10px 20px;
    /* text-align: left; */
  }

  .objective-num {
    margin-bottom: 0;
    margin-right: 10px;
  }

  .objective-text {
    width: 250px;
  }
}


/* -------------------------------------------- What We Do ---------------------------------------------------------- */

.wwd-img {
  width: 90vw;
  border-radius: 15px;
  margin-bottom: 20px;
}

.wwd-btn {
  padding: 15px 25px;
  width: fit-content;
  user-select: none;
  color: white;
  border-radius: 50px;
  text-align: center;
  background: linear-gradient(45deg, #db0004, #ffffff);
  font-weight: bold;
  margin: 10px auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.wwd-btn:hover {
  color: black;
  background: linear-gradient(90deg, #db0004, #ffffff);
}

.wwd-btn span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
  display: inline-block;
  box-sizing: border-box;
  margin: 0 5px;
}

@media (min-width: 800px) {
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .item {
    width: calc(50% - 20px);
    margin: 10px;
  }

  .wwd-img {
    width: 100%;
  }
}

/* Why Choose Us section */


.wcu {
  background: linear-gradient(to bottom, rgba(182, 0, 0, 0.9), rgba(131, 0, 0, 0.9));
  padding: 20px;
  backdrop-filter: blur(5px);
  color: rgb(255, 255, 255);
}

.quote {
  font-size: 2rem;
  color: rgba(255, 255, 255, 0.562);
  text-align: center;
  margin-bottom: 20px;
}

.wcu-p {
  color: white;
  text-align: center;
}

.wcu-b-p {
  color: white;
  background-color: rgb(114, 0, 0);
  border: 1px solid white;
  height: 200px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.wcu-b-p .p {
  font-style: italic;
  width: 60vw;
  text-align: center;
}

.wcu-box-section {
  display: flex;
  justify-content: center;
}

.wcu-box {
  margin: 10px;
  width: 50%;
  background-color: rgba(0, 0, 0, 0.212);
  border-radius: 5px;
  align-items: center;
}

.wcu-box:hover {
  background-color: rgba(0, 0, 0, 0.644);
  
}
.wcu-box img{
  width: 100px;
}

.img-div{
  border-radius: 5px;
  background-color: white;
  padding: 10px;
  margin: 10px;
}

@media (max-width: 800px) {
  /* .wcu-box img{
    width: 120px;
  } */

  .wcu-box-section{
    display: block;
  }
 
  .wcu-box {
    font-size: .8rem;
    width: 100%;
  }
}


/* ------------------------------------------- contact ---------------------------------------------------- */

.contact-section {
  /* padding: 10px; */
}

.contact-section .first-section {
  width: 100%;
  color: white;
  text-align: center;
  padding: 40px;
  padding-bottom: 60px;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  overflow: hidden; /* Ensure the pseudo-element does not overflow */
}

.contact-section .first-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  background: linear-gradient(270deg, #ffffff, #bb5a5a, #880000, #bb5a5a, #ffffff);
  background-size: 1000% 1000%;
  animation: gradientAnimation 15s ease infinite; /* Apply the animation */
  filter: blur(15px); /* Apply blur */
  z-index: -1; /* Position it behind the content */
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}



.contact-section .second-section {
  display: flex;
  width: 100%;
  /* color: white; */
}

.second-section .sub-1 {
  padding: 40px;
}

.sub-boxs {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.sub-box {
  display: flex;
  padding: 20px 0;
}

.sub-1 img{
  width: 50px;
  height: 50px;
}

.sub-details{
  /* text-align: center; */
  padding: 5px;
}

.sub-2 {
  background-color: rgba(255, 255, 255, 0.356);
  border-radius: 15px;
  border: 1px solid rgba(212, 212, 212, 0.493);
  padding: 20px;
  margin: 10px;
}


.sub-2 img{
  width: 50px;
  height: 50px;
  padding: 10px;
  background-color: rgba(212, 93, 93, 0.493);
  border-radius: 5px;
  margin: 10px;
}

.form input, textarea {
  padding: 15px;
  border-radius: 5px;
  border: .5px solid rgba(165, 165, 165, 0.116);
  margin: 20px 10px;
  width: 90%; /* Ensure inputs take full width */
  box-sizing: border-box;
}

.form input {
  width: 45%; 
}

.row {
  display: flex;
}




@media (max-width: 700px) {
  .form input {
    width: 90%; 
  }

  .contact-section .second-section {
    display: block;
  }
  .sub-boxs{
    display: block;
  }

}


.tosolve-container {
  margin: 0 10vw;
  overflow: hidden;
}

.tosolve-text {
  color: rgba(255, 255, 255, 0.144);
  font-size: 1.2rem;
  line-height: 1.2; /* Adjust line height as needed */
  transition: color 0.3s ease; /* Smooth color transition */
}

.tosolve-text.active {
  color: rgb(0, 0, 0);
}
